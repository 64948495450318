import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
require("swiper/swiper-bundle.css");
import Img from "gatsby-image";
import ReactPlayer from "react-player";

SwiperCore.use([Autoplay, EffectCoverflow]);

const query = graphql`
	query {
		strapiPageLivres {
			title
			slug
			seo {
				metaDescription
				metaTitle
			}
			video
		}
		slidePictures: allFile(
			filter: { sourceInstanceName: { eq: "books" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						fluid(srcSetBreakpoints: [260], maxWidth: 260) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
	}
`;

const Livres = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageLivres;

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<Swiper
						effect={`coverflow`}
						grabCursor={true}
						centeredSlides={true}
						slidesPerView={`auto`}
						coverflowEffect={{
							rotate: 50,
							stretch: 0,
							depth: 100,
							modifier: 1,
							slideShadows: true,
						}}
						speed={2000}
						autoplay={{ delay: 2000 }}
						className={`mt-5`}
					>
						{data.slidePictures.edges.map((image, index) => {
							let book = {
								url: "",
								title: "",
							};
							switch (index) {
								case 0:
									book.url = `https://amzn.eu/d/88LHAIZ`;
									book.title = `Ça y est, j'ai compris ! La PNL au service des apprentissages: Stratégies mentales, méthodes et outils | Alain Thiry`;
									break;
								case 1:
									book.url = `https://www.amazon.fr/gp/product/2807315607`;
									book.title = `Livre apprendre à apprendre avec la PNL | Alain Thiry`;
									break;
								case 2:
									book.url = `https://amzn.eu/d/0UARI2M`;
									book.title = `Améliorer les compétences en lecture grâce à la PNL | Alain Thiry`;
									break;
								case 3:
									book.url = `https://www.amazon.fr/gp/product/2804184757`;
									book.title = `Livre la pedagogie PNL | Alain Thiry`;
									break;
								case 4:
									book.url = `https://amzn.eu/d/gZT4sxh`;
									book.title = `Livre la Programmation Neuro Llinguistique | Alain Thiry`;
									break;
							}

							return (
								<SwiperSlide key={image.node.childImageSharp.fluid.src}>
									<div className={`slide`}>
										<Img
											fluid={image.node.childImageSharp.fluid}
											alt={book.title}
											title={book.title}
										/>
									</div>
									<div className={`button-action`}>
										<a href={book.url} target="_blank" rel="noopener">
											<button className={`btn btn--green`}>
												En savoir plus{" "}
											</button>
										</a>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className={`flex justify-center`}>
						<div className="player-wrapper">
							<ReactPlayer
								url={page.video}
								className="react-player"
								width="100%"
								height="100%"
								loop={true}
								controls={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Livres;
